import React, { useCallback } from "react"
import { Link } from "gatsby"
import InfoIcon from "@material-ui/icons/Info"
import WarningIcon from "@material-ui/icons/Warning"
import AppTitle from "../../components/appTitle/AppTitle"
import PageWrapper from "../../components/PageWrapper"
import { GlobalStateContext } from "../../context/GlobalContextProvider"

import * as overviewStyles from "./overview.module.css"

import {
  getTenancyOverview as apiGetTenancyOverview,
  getInvoices as apiGetInvoices,
} from "../../services/tenantsApiV2"
import Frame from "../../components/frame/Frame"
import Frames from "../../components/frames/Frames"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import ServicesUsageGraph from "../../components/servicesUsageGraph/ServicesUsageGraph"
import Button from "../../components/button/Button"
import InvoiceSummary from "../../components/invoiceSummary/InvoiceSummary"

import { convertToCurrency } from "../../util/finance"
import { b64toBlob } from "../../util/files"

export default ({ location, tenancyId }) => {
  const context = React.useContext(GlobalStateContext)

  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyDetails, setGettingTenancyDetails] = React.useState(true)
  const [overview, storeOverview] = React.useState(null)
  const [invoices, storeInvoices] = React.useState([])
  const [getTenancyError, setGetTenancyError] = React.useState(null)

  const getTenancyDetails = useCallback(async () => {
    try {
      setGettingTenancyDetails(true)
      const data = await Promise.all([
        apiGetTenancyOverview(tenancyId),
        apiGetInvoices(tenancyId),
      ])

      if (data[0] && data[0].value) {
        storeTenancy(data[0].value.tenancy)
        storeOverview(data[0].value)
      }

      if (data[1] && data[1].value) {
        storeInvoices(data[1].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyDetails(false)
  }, [tenancyId])

  React.useEffect(() => {
    getTenancyDetails()
  }, [context.loggedIn])

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancyDetails}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      {context.tenantUser && (
        <div className={overviewStyles.overview}>
          <AppTitle
            showBackHome={true}
            title={`Hi ${context.tenantUser.firstName}, here's your account overview!`}
          />
          {getTenancyError &&
            getTenancyError === "Please verify your account to continue" && (
              <VerifyAccountToContinue />
            )}
          {overview && (
            <div>
              {overview.tenancy.disputeRaised && (
                <Frame>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ marginRight: "12px" }}>
                      <WarningIcon />
                    </div>
                    <div>
                      <div style={{ marginBottom: 10 }}>
                        We've noticed that a bill doesn't look quite right, so
                        we have raised a reading dispute with our supplier to
                        have it corrected, this can take up to 12 weeks to be
                        resolved. In the meantime please provide an up to date
                        meter reading & we will keep you updated.
                      </div>
                      <div>Thanks for your patience.</div>
                    </div>
                  </div>
                </Frame>
              )}
              {!overview.tenancy.disputeRaised && (
                <Frame>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                    }}
                  >
                    <div style={{ marginRight: "12px" }}>
                      <InfoIcon />
                    </div>
                    {[
                      overview.householdPortalMessage,
                      overview.contactPortalMessage,
                    ].filter(message => message).length > 0 ? (
                      <div style={{ borderColor: "#F44336", color: "#F44336" }}>
                        {[
                          overview.householdPortalMessage,
                          overview.contactPortalMessage,
                        ].join(" ")}
                      </div>
                    ) : (
                      <div>No action required</div>
                    )}
                  </div>
                </Frame>
              )}
              <Frames>
                <Frame title="House Information">
                  <div>Address:</div>
                  <div
                    style={{
                      fontSize: 17,
                      fontWeight: "bold",
                      margin: "5px 0px",
                      fontWeight: "bold",
                    }}
                  >
                    {tenancy.name}
                  </div>
                  <div
                    style={{
                      margin: "5px 0px",
                    }}
                  >
                    Start date: {new Date(tenancy.startDate).toLocaleDateString("en-GB")}
                  </div>
                  <div
                    style={{
                      margin: "5px 0px",
                    }}
                  >
                    End date: {new Date(tenancy.endDate).toLocaleDateString("en-GB")}
                  </div>
                  <div
                    style={{
                      margin: "5px 0px",
                    }}
                  >
                    Move in date:{" "}
                    {new Date(tenancy.moveInDate).toLocaleDateString("en-GB")}
                  </div>
                </Frame>
                <Frame title="Set Up Progress">
                  <OptimizedTable
                    rows={[
                      // [
                      //   [
                      //     {
                      //       type: "title",
                      //       value: "Move in date confirmed",
                      //       color: "#37C63C",
                      //     },
                      //   ],
                      // ],
                      [
                        [
                          {
                            type: "title",
                            value: "All tenants signed",
                            color: overview.moveInMoveOutProgress
                              .allTenantsSigned
                              ? "#37C63C"
                              : "#EB545A",
                            visibility: overview.moveInMoveOutProgress.allTenantsSigned ? "visible" : "hidden",
                            tick: true
                          },
                        ],
                      ],
                      [
                        [
                          {
                            type: overview.moveInMoveOutProgress.openingMeterReadings? "title" : "link",
                            value: overview.moveInMoveOutProgress.openingMeterReadings ? "Opening meter reads submitted": "Action required - submit meter reading",
                            redirectUrl: `/tenants/app/tenancies/${tenancyId}/meters`,
                            color: overview.moveInMoveOutProgress
                              .openingMeterReadings
                              ? "#37C63C"
                              : "#EB545A",
                            visibility: overview.moveInMoveOutProgress.openingMeterReadings ? "visible" : "hidden",
                            tick: true
                          },
                        ],
                      ],
                      [
                        [
                          {
                            type: "title",
                            value: "All services live",
                            color: overview.moveInMoveOutProgress
                              .allServicesLive
                              ? "#37C63C"
                              : "#EB545A",
                            visibility: overview.moveInMoveOutProgress.allServicesLive ? "visible" : "hidden",
                            tick: true
                          },
                        ],
                      ],
                    ]}
                  />
                  <Link
                    to={`/tenants/app/tenancies/${tenancyId}/tenancy-information`}
                  >
                    <Button title="Check progress" />
                  </Link>
                </Frame>
              </Frames>
              <div>
                <Frame title="Latest/Past Invoices">
                  <div className={overviewStyles.invoices}>
                    {invoices.map(invoice => (
                      <InvoiceSummary invoice={invoice} />
                    ))}
                  </div>
                  {/* <Link to={`/tenants/app/tenancies/${tenancyId}/invoices`}>
                    <Button title="View all invoices" />
                  </Link> */}
                </Frame>
              </div>
            </div>
          )}
        </div>
      )}
    </PageWrapper>
  )
}
